import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer } from "antd";
import { User } from "firebase/auth";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { MenuData } from "../../../Data/MenuData";
import { IAccess } from "../../../Models/UsersModel";
import { RoutesPath } from "../../../Routes";

interface MobileMenuProps {
  onCloseMenu(): void;
  visible: boolean;
  accessRole: IAccess[];
  clickSignIn(): void;
  user: User | null | undefined;
  member: boolean;
}

const MobileMenu = ({
  onCloseMenu,
  visible,
  clickSignIn,
  user,
  accessRole,
  member,
}: MobileMenuProps): ReactElement => {
  const menuItemsSwitch = (menuItem: any) => {
    switch (menuItem.menuLabel) {
      case "Home":
        return <></>;
      case "About":
        return <></>;
      case "Membership Center":
        return member ? (
          <li className="menu-items" key="Membership Center">
            <Link to={menuItem.path} onClick={() => onCloseMenu()}>
              {menuItem.menuLabel}
            </Link>
          </li>
        ) : (
          <></>
        );
      case "Member Perks":
        return member ? (
          <li className="menu-items" key="Membership Center">
            <Link to={menuItem.path} onClick={() => onCloseMenu()}>
              {menuItem.menuLabel}
            </Link>
          </li>
        ) : (
          <></>
        );
      case "JOIN THE CLUB & SAVE":
        return member ? (
          <></>
        ) : (
          <li className="menu-items" key="Membership Center">
            <Link to={menuItem.path} onClick={() => onCloseMenu()}>
              {menuItem.menuLabel}
            </Link>
          </li>
        );
      default:
        return (
          <li className="menu-items" key="Membership Center">
            <Link to={menuItem.path} onClick={() => onCloseMenu()}>
              {menuItem.menuLabel}
            </Link>{" "}
          </li>
        );
    }
  };

  return (
    <Drawer
      title="Menu"
      placement="top"
      onClose={onCloseMenu}
      open={visible}
      getContainer={false}
      closeIcon={<FontAwesomeIcon icon={faBars as IconProp} color="#0068EF" />}
      className="main-menu-mobile"
      height={500}
    >
      <div className="sing-in-link">
        {!user ? (
          <span>
            <strong>Hi!</strong>&nbsp;
            <button
              className="btn btn-link"
              type="button"
              onClick={clickSignIn}
            >
              Sign in
            </button>
            &nbsp; for faster booking
          </span>
        ) : (
          <span>
            <strong>Hi! &nbsp;{user?.email}</strong>
          </span>
        )}
      </div>
      <ul className="menu-items-group">
        {accessRole.filter((item) => item.path === "sales").length > 0 && (
          <li className="menu-items">
            <Link
              to={RoutesPath.accounts}
              className="text-secondary"
              onClick={() => onCloseMenu()}
            >
              Account Management
            </Link>
          </li>
        )}
        {accessRole.filter((item) => item.path === "bookings").length > 0 &&
          accessRole.length === 1 && (
            <li className="menu-items">
              <Link to={RoutesPath.membership} onClick={() => onCloseMenu()}>
                Membership Center
              </Link>
            </li>
          )}
        {MenuData.map((menuItem, index) =>
          !menuItem.requireLogin ||
          (user && menuItem.accessControl === "") ||
          (user &&
            accessRole.find(
              (x) =>
                x.path ===
                menuItem.accessControl.substring(
                  1,
                  menuItem.accessControl.length
                )
            ) !== undefined) ? (
            <>{menuItemsSwitch(menuItem)}</>
          ) : (
            ""
          )
        )}
      </ul>
    </Drawer>
  );
};
export default MobileMenu;
