import React, { ReactElement, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faSignOut,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Auth } from "firebase/auth";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useAuthState } from "react-firebase-hooks/auth";
import MainLogo from "../MainLogo";
import CurrencySelect from "./CurrencySelect";
import { useViewport } from "../../Context/useViewport";
import MobileMenu from "./MobileMenu";
import { logout } from "../../Auth/firebase";
import { MenuData } from "../../Data/MenuData";
import ProfileBoxMenu from "./ProfileBoxMenu";
import ModalAuthManagement from "./ModalAuthManagement";
import LoginForm from "../AccountManagment/LoginForm";
import Reset from "../AccountManagment/Reset";
import Register from "../AccountManagment/Register";
import { IAccess } from "../../Models/UsersModel";
import { SearchContext } from "../../Context/context";
import { ISearch } from "../../Models/Search";
import { useScrollLock } from "../../helpers/scrollLock";
import { RoutesPath } from "../../Routes";
import { IWebConfigContext } from "../../Models/SettingsModel";
import { WebConfigContext } from "../../Context/WebConfigContext";
import { getMembershipStatus } from "../../helpers/requestMembership";
import { isMembershipApproved } from "../../Data/MembershiStatusData";

interface MainHeaderProps {
  className?: string;
  accessRole: IAccess[];
  setAccesRole: React.Dispatch<React.SetStateAction<IAccess[]>>;
  auth: Auth;
}

const MainHeader = ({
  className,
  accessRole,
  auth,
  setAccesRole,
}: MainHeaderProps): ReactElement => {
  const { searchContent } = useContext<any | ISearch>(SearchContext);
  const { webConfigContext } = useContext<any | IWebConfigContext>(
    WebConfigContext
  );
  const { lockScroll, unlockScroll } = useScrollLock();
  const { width } = useViewport();
  const [menuMobileVisible, setMenuMobileVisible] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [user] = useAuthState(auth);
  const [childrenModal, setChildrenModal] = useState<React.ReactElement | null>(
    <LoginForm onChangeForm={() => {}} auth={auth} />
  );
  const [typeForm, setTypeForm] = useState<string>("login");
  const [titleModal, setTitleModal] = useState<string>("Login");
  const [subtitleModal, setSubtitleModal] = useState<string>("");
  const [linkMessageModal, setLinkMessageModal] = useState<string>("");
  const [member, setMember] = useState(false);
  const navigate = useNavigate();

  const onClose = () => {
    unlockScroll();
    setMenuMobileVisible(false);
  };
  const onOpen = () => {
    lockScroll();
    setMenuMobileVisible(true);
  };

  const handlerClickModalClose = () => {
    if (!modalOpen) {
      lockScroll();
    } else {
      unlockScroll();
    }
    setModalOpen(!modalOpen);
    setTypeForm("login");
  };

  const handlerChangeForm = (type: string) => {
    setTypeForm(type);
  };

  const menuItemsSwitch = (menuItem: any) => {
    switch (menuItem.menuLabel) {
      case "Home":
        return <></>;
      case "About":
        return <></>;
      case "JOIN THE CLUB & SAVE":
        return <></>;
      case "My Bookings":
        return <></>;
      case "Membership Center":
        return member ? (
          <Link to={menuItem.path}>{menuItem.menuLabel}</Link>
        ) : (
          <></>
        );
      case "Member Perks":
        return member ? (
          <Link to={menuItem.path}>{menuItem.menuLabel}</Link>
        ) : (
          <></>
        );
      case "FAQ":
        return member ? (
          <Link to={menuItem.path}>{menuItem.menuLabel}</Link>
        ) : (
          <></>
        );
      default:
        return <Link to={menuItem.path}>{menuItem.menuLabel}</Link>;
    }
  };

  const setMembershipStatus = async (email: string): Promise<any> => {
    try {
      const membershipResponse = await getMembershipStatus(email);
      setMember(isMembershipApproved(membershipResponse[0].orderStatus));
    } catch (error) {
      setMember(false);
    }
  };

  useEffect(() => {
    if (user && user.email) {
      try {
        setMembershipStatus(user.email);
      } catch (error) {
        console.error(error);
      }
    }
    if (user === null) setAccesRole([]);
    if (modalOpen && user) setModalOpen(false);
  }, [user]);

  useEffect(() => {
    if (typeForm === "login") {
      setChildrenModal(
        <LoginForm onChangeForm={handlerChangeForm} auth={auth} />
      );
      setTitleModal("Login");
      setSubtitleModal("");
      setLinkMessageModal("");
    } else if (typeForm === "reset") {
      setChildrenModal(<Reset auth={auth} />);
      setTitleModal("Forgot your password?");
      setSubtitleModal(
        "Reset your password to continue enjoying faster checkouts and easy access to your trip info. Just enter your email so we can send a secure link."
      );
      setLinkMessageModal("Continue Browsing or checking out as a guest");
    } else if (typeForm === "register") {
      setChildrenModal(
        <Register onChangeForm={handlerChangeForm} auth={auth} />
      );
      setTitleModal("Create your account");
      setSubtitleModal("");
      setLinkMessageModal("");
    }
  }, [typeForm]);

  return width > 985 ? (
    <nav className={`navbar navbar-expand-lg bg-white ${className}`}>
      <div className="container">
        <MainLogo
          className="navbar-brand"
          checkIn={searchContent.checkIn}
          checkOut={searchContent.checkOut}
        />
        <ul className="navbar-nav nav" id="navbar-right">
          {accessRole.filter((item) => item.path === "sales").length > 0 && (
            <li className="link-right nav-link">
              <Link to={RoutesPath.accounts}>Account Managment</Link>
            </li>
          )}
          {MenuData.map((menuItem, index) =>
            !menuItem.requireLogin ||
            (user && menuItem.accessControl === "") ||
            (user &&
              accessRole.find(
                (x) =>
                  x.path ===
                  menuItem.accessControl.substring(
                    1,
                    menuItem.accessControl.length
                  )
              ) !== undefined) ? (
              <li className="link-right nav-link" key={index}>
                {menuItemsSwitch(menuItem)}
              </li>
            ) : (
              ""
            )
          )}
          {user &&
            accessRole.length === 1 &&
            accessRole[0].path === "bookings" && (
              <li className="link-right nav-link">
                <Link to={RoutesPath.bookings}>My Bookings</Link>
              </li>
            )}

          {user ? (
            <ProfileBoxMenu user={user} />
          ) : (
            <ModalAuthManagement
              title={titleModal}
              subtitle={subtitleModal}
              clickOnClose={handlerClickModalClose}
              modalOpen={modalOpen}
              childrenModal={childrenModal}
              linkModal={linkMessageModal}
            />
          )}
          {!member && (
            <button
              className="btn rounded-pill ms-3 home-signup-btn"
              onClick={() => {
                window.location.href = `${RoutesPath.home}/member-login/#membership-form`;
              }}
            >
              JOIN THE CLUB & SAVE
            </button>
          )}
          <li className="link-right nav-link" style={{ display: "none" }}>
            <CurrencySelect />
          </li>
        </ul>
      </div>
    </nav>
  ) : (
    <>
      <nav className="navbar-nav">
        <div className="container-fluid">
          <div className="row">
            <div className="col-2">
              <button
                className="btn button-menu-mobile"
                type="button"
                onClick={() => onOpen()}
              >
                <FontAwesomeIcon icon={faBars as IconProp} color="#0068EF" />
              </button>
            </div>
            <div className="col-8">
              <MainLogo
                className="navbar-brand"
                checkIn={searchContent.checkIn}
                checkOut={searchContent.checkOut}
              />
            </div>
            <div className="col-2">
              {user ? (
                <div className="text-end">
                  <button
                    className="btn btn-light p-3 "
                    type="button"
                    onClick={() => {
                      logout(searchContent.auth);
                      navigate(`${RoutesPath.root}`);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSignOut as IconProp}
                      color="#0068EF"
                    />
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-light p-3"
                  type="button"
                  onClick={() => navigate(RoutesPath.SignIn)}
                >
                  <FontAwesomeIcon
                    icon={faUserCircle as IconProp}
                    color="#0068EF"
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </nav>
      <MobileMenu
        user={user}
        accessRole={accessRole}
        onCloseMenu={onClose}
        visible={menuMobileVisible}
        clickSignIn={() => {
          navigate(RoutesPath.SignIn);
          setMenuMobileVisible(false);
        }}
        member={member}
      />
      <ModalAuthManagement
        title={titleModal}
        subtitle={subtitleModal}
        clickOnClose={handlerClickModalClose}
        modalOpen={modalOpen}
        childrenModal={childrenModal}
        linkModal={linkMessageModal}
      />
    </>
  );
};

export default MainHeader;
