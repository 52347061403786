import React, { ReactElement, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RoutesPath } from "../../Routes";
import HotelDetail from "../../Pages/HotelDetail";
import CheckoutForm from "../../Pages/Checkout";
import LandingPage from "../../Pages/LandingPage";
import Results from "../../Pages/Results";
import NotFound from "../../Pages/404";
import ThankYou from "../../Pages/Checkout/Thankyou";
import BookList from "../../Pages/Bookings/BookList";
import BookDetail from "../../Pages/Bookings/BookDetail";
import AccountManagement from "../../Pages/Accounts";
import BookListAdmin from "../../Pages/Bookings/BookListAdmin";
import Loading from "../../Pages/Loading";
import { IAccess } from "../../Models/UsersModel";
import AccountsRoles from "../../Pages/Accounts/Roles";
import AccountsSettings from "../../Pages/Accounts/Settings";
import BookCancel from "../../Pages/Bookings/BookCancel";
import DebugPage from "../../Pages/DebugPage";
import Reports from "../../Pages/Accounts/Reports";
import MembershipPage from "../../Pages/Membership";
import SignIn from "../../Pages/Sign/SingIn";
import SignUp from "../../Pages/Sign/SignUp";
import ResetPass from "../../Pages/Sign/Reset";
import LocationRadius from "../../Pages/Accounts/Settings/LocationRadius";
import HotelDetailSimple from "../../Pages/HotelDetail/HotelExtendendInfo";
import SalesManagment from "../../Pages/Accounts/SalesManagment";
import UserList from "../../Pages/Accounts/Users";
import { getMembershipStatus } from "../../helpers/requestMembership";
import { isMembershipApproved } from "../../Data/MembershiStatusData";
import { useAuthState } from "react-firebase-hooks/auth";
import { Auth } from "firebase/auth";
import MemberPerks from "../../Pages/MemberPerks";
import FaqPage from "../../Pages/Faq";
import RateFilterTable from "../../Pages/Accounts/Settings/RateFilters";

interface RouterComponentProps {
  openMenu: boolean;
  accessRoles: IAccess[];
  loading: boolean;
  setAccesRole: React.Dispatch<React.SetStateAction<IAccess[]>>;
  setIsSearchBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  auth: Auth | undefined;
}

const RouterComponent = ({
  openMenu,
  accessRoles,
  loading,
  setAccesRole,
  setIsSearchBoxVisible,
  auth,
}: RouterComponentProps): ReactElement => {
  const [member, setMember] = useState(false);
  if (auth) {
    const [user] = useAuthState(auth);

    const setMembershipStatus = async (email: string): Promise<any> => {
      try {
        const membershipResponse = await getMembershipStatus(email);
        setMember(isMembershipApproved(membershipResponse[0].orderStatus));
      } catch (error) {
        setMember(false);
      }
    };

    useEffect(() => {
      if (user && user.email) {
        try {
          setMembershipStatus(user.email);
        } catch (error) {
          console.error(error);
        }
      }
    }, [user]);
  }

  return (
    <Routes>
      {loading && <Route path="*" element={<Loading />} />}
      {!loading && (
        <>
          <Route path="*" element={<NotFound />} />
          <Route
            path={`${RoutesPath.checkoutForm}`}
            element={<CheckoutForm />}
          />
          <Route
            index
            element={
              <LandingPage setIsSearchBoxVisible={setIsSearchBoxVisible} />
            }
          />
          <Route path={`${RoutesPath.hotelDetail}`} element={<HotelDetail />} />
          <Route path={`${RoutesPath.thankyou}`} element={<ThankYou />} />
          <Route
            path={`${RoutesPath.results}`}
            element={<Results menuOpen={openMenu} />}
          />
          {accessRoles.filter((item) => item.path === "sales").length > 0 && (
            <>
              <Route path={`${RoutesPath.Sales}`} element={<BookListAdmin />} />
              <Route
                path={`${RoutesPath.bookings}`}
                element={<BookListAdmin />}
              />
              <Route
                path={`${RoutesPath.bookDetail}`}
                element={<BookDetail />}
              />
              <Route
                path={`${RoutesPath.HotelDetailSimple}`}
                element={<HotelDetailSimple />}
              />
              <Route
                path={`${RoutesPath.accounts}`}
                element={<SalesManagment />}
              />
            </>
          )}
          {accessRoles.filter((item) => item.path === "bookings").length >
            0 && (
            <>
              <Route path={`${RoutesPath.bookings}`} element={<BookList />} />
              <Route
                path={`${RoutesPath.bookDetail}`}
                element={<BookDetail />}
              />
              <Route
                path={`${RoutesPath.HotelDetailSimple}`}
                element={<HotelDetailSimple />}
              />
            </>
          )}
          {accessRoles.length === 0 ? (
            <>
              <Route
                path={`${RoutesPath.SignIn}`}
                element={<SignIn setAccesRole={setAccesRole} />}
              />
              <Route
                path={`${RoutesPath.register}`}
                element={<SignUp setAccesRole={setAccesRole} />}
              />
              <Route path={`${RoutesPath.reset}`} element={<ResetPass />} />
            </>
          ) : (
            <>
              <Route
                path={`${RoutesPath.SignIn}`}
                element={<Navigate to={`${RoutesPath.bookings}`} />}
              />
              <Route
                path={`${RoutesPath.register}`}
                element={<NotFound openMenu="You must log out" />}
              />
            </>
          )}
          <Route path={`${RoutesPath.bookCancel}`} element={<BookCancel />} />
          <Route path={`${RoutesPath.debug}`} element={<DebugPage />} />
          {member && (
            <>
              <Route
                path={`${RoutesPath.membership}`}
                element={<MembershipPage />}
              />
              <Route
                path={`${RoutesPath.memberPerks}`}
                element={<MemberPerks />}
              />
              <Route path={`${RoutesPath.faqs}`} element={<FaqPage />}/>
            </>
          )}

          {accessRoles.filter((item) => item.path === "accounts").length >
            0 && (
            <>
              <Route
                path={`${RoutesPath.accounts}`}
                element={<AccountManagement />}
              />
              <Route
                path={`${RoutesPath.accountsBookings}`}
                element={<BookListAdmin />}
              />
              <Route path={`${RoutesPath.roles}`} element={<AccountsRoles />} />
              <Route path={`${RoutesPath.users}`} element={<UserList />} />
              <Route
                path={`${RoutesPath.settings}`}
                element={<AccountsSettings />}
              />
              <Route
                path={`${RoutesPath.rateFilter}`}
                element={<RateFilterTable />}
              />
              <Route
                path={`${RoutesPath.locationRadius}`}
                element={<LocationRadius />}
              />
              <Route path={`${RoutesPath.reports}`} element={<Reports />} />
            </>
          )}
        </>
      )}
    </Routes>
  );
};
export default RouterComponent;